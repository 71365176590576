import React from 'react'
import { getHexColor, OneColorIconProps } from '../utils'

export const ShareOutlineIcon: React.FC<OneColorIconProps> = ({ size = 18, color = 'white', className }) => {
  const hex = getHexColor(color)

  return (
    <svg
      className={className}
      width={size}
      height={size}
      viewBox="0 0 18 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.48003 0.485816C10.0714 0.21384 10.7669 0.310987 11.2611 0.734576L16.7513 5.44046C17.1207 5.7571 17.3333 6.21934 17.3333 6.70589C17.3333 7.19243 17.1207 7.65468 16.7513 7.97131L11.2611 12.6772C10.7669 13.1008 10.0714 13.1979 9.48003 12.926C8.88869 12.654 8.50979 12.0627 8.50979 11.4118V10.3814C7.88802 10.4316 7.40425 10.5296 6.92449 10.7133C6.07912 11.0371 5.09302 11.6914 3.47559 13.2137C2.95476 13.7039 2.17925 13.8071 1.54832 13.4702C0.917398 13.1334 0.571745 12.4315 0.689327 11.726C1.13329 9.06221 2.26804 6.88279 3.96839 5.36462C5.2588 4.21246 6.81637 3.49436 8.50979 3.21356V2C8.50979 1.34912 8.88869 0.757791 9.48003 0.485816ZM10.1765 4.7451C7.19155 4.7451 4.66103 6.22188 3.27663 9.00251C2.84221 9.87507 2.52065 10.876 2.33332 12C3.20103 11.1833 3.93587 10.566 4.60356 10.0995C5.99898 9.12456 7.1011 8.80866 8.50979 8.70975C9.01639 8.67418 9.56265 8.66667 10.1765 8.66667V11.4118L15.6667 6.70589L10.1765 2V4.7451Z"
        fill={hex}
      />
    </svg>
  )
}
