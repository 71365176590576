import { Maybe } from 'graphql/jsutils/Maybe'
import { logger } from '@/utils/logging'
import { CatalogTitle, findHydraExternalId, hasHydraIdAndSlug } from '../ContentCatalog'
import { CreateShareLinkRequest, CreateShareLinkResponse, SharedContent } from './types'

const SHARE_API_HOST = 'https://share.angelstudios.io'

export type ShareLink = {
  id: string
  user_id: string
  content_id: string
  metadata: LinkMetadatum
  group_code: number
  max_claims: number
  updatedAt: string
  createdAt: string
}

export type LinkMetadatum = {
  id: string
  variant_slug: string
  content_id: string
  opengraph_image: string
  opengraph_title: string
  opengraph_description: string
  updatedAt: string
  createdAt: string
}

export type LinkClaim = {
  id: string
  user_id: string
  share_link: ShareLink
  updatedAt: string
  createdAt: string
}

interface CreateOneToManyParams {
  accessToken: string
  watchableTitle: CatalogTitle
  baseUrl: string
  episodeId: string
}

export async function createOneToManyShareLink({
  accessToken,
  watchableTitle,
  baseUrl,
  episodeId,
}: CreateOneToManyParams): Promise<string | null> {
  if (!hasHydraIdAndSlug(watchableTitle)) {
    logger().error(`This content catalog title is missing required hydra id or slug! A share link cannot be created.`, {
      watchableTitle,
    })
    return null
  }

  if (findHydraExternalId(watchableTitle) !== episodeId) {
    logger().error(
      `There is a mismatch between the hydra id obtained from hydra and the hydra id linked on the content catalog title record.`,
      { watchableTitle, episodeId },
    )
    return null
  }

  type Response = Maybe<{ success: boolean; shareLink: Maybe<ShareLink> }>
  const result: Response = await contentSharingPost('/shareLink', accessToken, { contentId: watchableTitle.id })

  if (!result) {
    logger().error('Share link creation failed: No response from API', {
      watchableTitle,
      episodeId,
    })
    return null
  }

  if (!result.success) {
    logger().error('Share link creation failed: API returned failure', {
      watchableTitle,
      episodeId,
      apiResponse: result,
    })
    return null
  }

  if (!result.shareLink) {
    logger().error('Share link creation failed: No share link in response', {
      watchableTitle,
      episodeId,
      apiResponse: result,
    })
    return null
  }

  const shareLink = result.shareLink
  const link = `${baseUrl}/watch/shared/${shareLink.id}`
  logger().info('Successfully created a share link', { watchableTitle, shareLink, link })
  return link
}

interface GetShareLinkParams {
  id: string
}

export async function getShareLink({ id }: GetShareLinkParams): Promise<ShareLink | null> {
  type Response = Maybe<{ success: boolean; shareLink: Maybe<ShareLink> }>
  const result: Response = await contentSharingGet(`/shareLink/${id}`)

  if (!result || !result.success || !result.shareLink) return null

  logger().debug('Successfully fetched a share link', { shareLink: result.shareLink })
  return result.shareLink
}

interface ClaimOneToManyParams {
  accessToken: string
  shareLinkId: string
}

export async function claimOneToManyShareLink({
  accessToken,
  shareLinkId,
}: ClaimOneToManyParams): Promise<LinkClaim | null> {
  type Response = Maybe<{ success: boolean; claim: Maybe<LinkClaim> }>
  const result: Response = await contentSharingPost('/claim', accessToken, { shareLinkId })

  if (!result || !result.success || !result.claim) return null
  const claim = result.claim
  logger().info('Successfully claimed a share link', { shareLinkId, claim })
  return claim
}

async function contentSharingPost<T, R>(apiPath: string, accessToken: string, payload: T): Promise<R | null> {
  const start = Date.now()
  logger().info('User initiated share link creation', { payload, apiPath })
  try {
    const response = await fetch(`${getBaseUrl()}${apiPath}`, {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: getHeaders(accessToken),
    })

    if (!response.ok) {
      const responseText = await response.text()
      const responseJson = await response.json()
      logger().error(`Content Sharing API Post request failed with no response.ok!`, {
        apiPath,
        payload,
        responseText,
        responseJson,
        elapsed: Date.now() - start,
      })
      return null
    }

    const result = await response.json()
    return result
  } catch (err) {
    logger().error(`Content Sharing API Post request failed with an unknown error!`, {
      apiPath,
      payload,
      err,
      elapsed: Date.now() - start,
    })
    return null
  }
}

async function contentSharingGet<R>(apiPath: string, accessToken?: string): Promise<R | null> {
  const start = Date.now()
  try {
    const response = await fetch(`${getBaseUrl()}${apiPath}`, {
      method: 'GET',
      headers: getHeaders(accessToken),
    })

    if (!response.ok) {
      const responseText = await response.text()
      const responseJson = await response.json()
      logger().error(`Content Sharing API Get request failed with no response.ok!`, {
        elapsed: Date.now() - start,
        apiPath,
        responseText,
        responseJson,
      })
      return null
    }

    const result = await response.json()
    return result
  } catch (err) {
    logger().error(`Content Sharing API Get request failed with an unknown error!`, {
      apiPath,
      err,
      elapsed: Date.now() - start,
    })
    return null
  }
}

function getBaseUrl() {
  if (process.env.NEXT_PUBLIC_CONTENT_SHARING_API_BASE_URL === undefined) {
    throw new Error('The required environment variable for the content sharing api is undefined!')
  }
  return `${process.env.NEXT_PUBLIC_CONTENT_SHARING_API_BASE_URL}/api`
}

function getHeaders(accessToken: string | undefined): HeadersInit {
  const headers: HeadersInit = { 'Content-Type': 'application/json' }
  if (accessToken) headers.Authorization = accessToken
  return headers
}

/**
 * @deprecated This function uses the now deprecated sharing service. If 1:1 sharing is used again, need to update usages to work with the new content sharing service.
 * @param data
 * @returns
 */
export const createShareLink = async (data: CreateShareLinkRequest): Promise<CreateShareLinkResponse | undefined> => {
  try {
    const response = await fetch(`${SHARE_API_HOST}/create`, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
      },
    })

    if (!response.ok) {
      const errorText = await response.text()
      logger().error('Failed to create share link with no response.ok', errorText)
    }

    return response.json()
  } catch (error) {
    logger().error('Failed to create share link with unknown error', error)
    return undefined
  }
}

/**
 * @deprecated
 * @param guid
 * @returns
 */
export const getSharedContent = async (guid: string): Promise<SharedContent | undefined> => {
  try {
    const response = await fetch(`${SHARE_API_HOST}/shared/${guid}`, {
      headers: {
        'X-API-KEY': process.env.SHARE_API_KEY as string,
      },
    })

    if (!response.ok) {
      const errorText = await response.text()
      logger().error('Failed to get shared content', errorText)
    }

    return response.json()
  } catch (error) {
    logger().error('Failed to get shared content', error)
    return undefined
  }
}

/**
 * @deprecated
 * @param guid
 * @param id
 * @returns
 */
export const claimSharedContent = async (guid: string, id: string): Promise<SharedContent | undefined> => {
  try {
    const response = await fetch(`${SHARE_API_HOST}/claim/${id}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ recipient_uuid: guid }),
    })

    if (!response.ok) {
      const errorText = await response.text()
      logger().error('Failed to claim shared content', errorText)
    }

    return
  } catch (error) {
    logger().error('Failed to claim shared content', error)
    return undefined
  }
}

/**
 * @deprecated
 * @param url
 * @returns
 */
export const extractLastGuid = (url: string): string => {
  try {
    const urlObj = new URL(url)
    const pathSegments = urlObj.pathname.split('/')
    return pathSegments[pathSegments.length - 1]
  } catch (error) {
    logger().error('Error extracting last guid from url', { url }, error as Error)
    return ''
  }
}
