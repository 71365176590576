import { When } from 'react-if'
import { EyebrowMD } from '@/atoms/Text'
import { notRatedSlugs } from '@/utils/SpecialProjectUtils'
import { useTranslate } from '@/utils/translate/translate-client'

interface ContentRatingBadgeProps {
  contentRating?: string
  slug?: string
}

export const ContentRatingBadge: React.FC<ContentRatingBadgeProps> = ({ contentRating, slug }) => {
  const { t } = useTranslate('watch')
  const notYetRatedText = t('notYetRated', 'Not Yet Rated')

  const formattedRating =
    slug && notRatedSlugs.includes(slug) ? notYetRatedText : contentRating?.toUpperCase().replace('_', '-')

  return (
    <When condition={formattedRating && formattedRating?.length > 0}>
      <EyebrowMD className="w-fit whitespace-nowrap rounded border px-1">{formattedRating}</EyebrowMD>
    </When>
  )
}
