import { Button } from '@/atoms/Button'
import { CaptionSM } from '@/atoms/Text'
import { useTranslate } from '@/utils/translate/translate-client'

export const ContentRestrictedButton = () => {
  const { t } = useTranslate('watch')
  return (
    <Button className="w-[220px]" disabled outline variant="white">
      <CaptionSM weight="bold" className="text-center" color="black">
        {t('contentRestricted', 'This video is restricted')}
      </CaptionSM>
    </Button>
  )
}
