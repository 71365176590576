import React from 'react'
import classNames from 'classnames'
import { Button } from '@/atoms/Button'
import { CaptionSM } from '@/atoms/Text'
import { useTranslate } from '@/utils/translate/translate-client'

interface GeoBlockedButtonProps {
  className?: string
}

export const GeoBlockedButton: React.FC<GeoBlockedButtonProps> = ({ className }) => {
  const { t } = useTranslate('watch')
  return (
    <Button className={classNames('w-[220px]', className)} disabled outline variant="white">
      <CaptionSM weight="bold" className="text-center" color="black">
        {t('unavailableInLocation', 'This video is not currently available in your location')}
      </CaptionSM>
    </Button>
  )
}
